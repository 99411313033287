document.addEventListener("turbolinks:load", function() {
  if (['development', 'test'].includes(gon.app_env)) {
    window.onRecaptchaSubmit = function (e) {
      return true;
    }
  } else {
    $.getScript(`https://www.google.com/recaptcha/api.js?render=${gon.captcha_key}`, function () {
    });
    $('input[name=g-recaptcha-response]').each(function () {
      $(this).closest('form').on('submit', function(e) {
        e.preventDefault();
        if ($(e.currentTarget).hasClass('needs-validation')) {
          if (e.currentTarget.checkValidity() === false) {
            return false
          }
        }
        return false
      })
    })
    window.onRecaptchaSubmit = function (e) {
      grecaptcha.ready(function() {
        try {
          grecaptcha.execute(gon.captcha_key, { action: 'submit' }).then(function(token) {
            e.querySelector("[name='g-recaptcha-response']").value = token
            if ($(e).data('remote')) { // workaround for ajax forms
              Rails.handleRemote.call(e, event)
            } else {
              e.submit();
            }
          })
        } catch {
          const errorBlock = $('<div class="container-fluid p-0 message_block">'
            + '<div id="message_block">'
            + '<div class="alert alert-danger fade show m-0 text-center alert-dismissible">'
            + '<ul class="ul-disc m-0"><li>Captcha error. Please try again.</li></ul>'
            + '</div></div>'
            + '</div>')
          $(e).before(errorBlock)
        }
      });
      return false;
    }
  }
})
